import { SERVER } from '../utils/api'

export const defaultMenuType = "menu-default";
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const imagePath = `${SERVER}/storage`
export const salt = 'conquerca285';

export const imgUploadLimit = 500;
export const imgRatio_1600_870 = 1600 / 870;
export const imgRatio_1024_768 = 1024 / 768;
export const imgRatio_1242_2208 = 1242 / 2208;
export const imgRatio_1176_695 = 1176 / 695;
export const imgRatio_588_695 = 588 / 695;
export const imgRatio_420_420 = 1;

export const BANNER_PAGE_ID = 1;
export const NEWS_PAGE_ID = 2;
export const PRODUCT_PAGE_ID = 3;
export const CASE_PAGE_ID = 4;
export const SHOWROOM_PAGE_ID = 5;
export const FAQ_PAGE_ID = 6;

export const REQUEST_SUCCESS = 0;
export const ERROR_LOGIN = 102;
export const ERROR_DUPLICATE = 103;
export const ERROR_LIMIT_TOP = 104;
export const ERROR_LIMIT_CAROUSEL = 105;
export const ERROR_PERMISSION = 3;
export const ERROR_PARAM = 4;
