import axios from 'axios';

export const SERVER = "https://api.li-ding.tw";
const API_SERVER = `${SERVER}/v1`;

const apiRequest = axios.create({
  baseURL: API_SERVER,
  headers: {
    "Content-type": "application/json"
  }
});

apiRequest.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? token : '';
  return config;
});

const config = { headers: { 'Content-Type': 'multipart/form-data' } }

export const userLogin = data => apiRequest.post('/login', data);
export const UserLogout = data => apiRequest.post('/admin/user/logout', data);

export const userCreate = data => apiRequest.post('/admin/user', data);
export const userForgotPassword = data => apiRequest.post('/forgotPassword', data);
export const UserEdit = data => apiRequest.put(`/admin/user/${data.userId}`, data.userInfo);
export const userDelete = data => apiRequest.delete(`/admin/user/${data}`);
export const userGetList = () => apiRequest.get(`/admin/user`);
export const userGet = data => apiRequest.get(`/admin/user/${data}`);

export const photoUpload = data => apiRequest.post('/admin/photo', data, config);
export const photoDelete = data => apiRequest.delete(`/admin/photo/${data}`);

export const productInfoGetByAdmin = data => apiRequest.post("/admin/product/productInfo", data);
export const productCreate = data => apiRequest.post('/admin/product', data, config);
export const productEdit = data => apiRequest.put(`/admin/product/${data.itemId}`, data.content, config);
export const productDelete = data => apiRequest.delete(`/admin/product/${data}`);

export const ListSort = data => apiRequest.put(`/admin/list/${data.pageId}/sort`, data.content);
export const ListToggle = data => apiRequest.put(`/admin/list/${data.pageId}/toggle`, data.content);
export const ListMultiSelect = data => apiRequest.put(`/admin/list/${data.pageId}/multiSelect`, data.selectInfo);


export const bannerCreate = data => apiRequest.post('/admin/banner', data);
export const bannerDelete = data => apiRequest.delete(`/admin/banner/${data}`);
export const heroGetByAdmin = () => apiRequest.get(`/admin/banner`);

export const newsGetByAdmin = () => apiRequest.get(`/admin/news`)
export const newsCreate = data => apiRequest.post('/admin/news', data, config);
export const newsEdit = data => apiRequest.put(`/admin/news/${data.itemId}`, data.content, config);
export const newsDelete = data => apiRequest.delete(`/admin/news/${data}`);

export const ProductInfoGetByAdmin = data => apiRequest.post(`/admin/product/productInfo`, data);

export const CaseInfoGetByAdmin = data => apiRequest.get(`/admin/cases/caseInfo/${data}`);
export const CaseCreate = data => apiRequest.post('/admin/cases', data, config);
export const CaseEdit = data => apiRequest.put(`/admin/cases/${data.itemId}`, data.content, config);
export const CaseDelete = data => apiRequest.delete(`/admin/cases/${data}`);

export const FaqGetByAdmin = data => apiRequest.get(`/admin/faq/${data}`);
export const FaqCreate = data => apiRequest.post('/admin/faq', data, config);
export const FaqEdit = data => apiRequest.put(`/admin/faq/${data.itemId}`, data.content, config);
export const FaqDelete = data => apiRequest.delete(`/admin/faq/${data}`);

export const showroomCreate = data => apiRequest.post('/admin/showroom', data);
export const showroomDelete = data => apiRequest.delete(`/admin/showroom/${data}`);
export const showroomGetByAdmin = () => apiRequest.get(`/admin/showroom`);


