import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../App'


const PrivateRoute = ({ component: Component, ...rest }) => {
    
    const { isToken } = useContext(AuthContext);
   
    
    return (
      <Route
        {...rest}
        render={props =>
            isToken ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/user/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }

  export { PrivateRoute };