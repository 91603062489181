import React, { createContext, Suspense, useState } from 'react';
import "./assets/css/vendor/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import './assets/fonts/simple-line-icons/css/simple-line-icons.css';
import './assets/css/icomoon.css'
import "rc-switch/assets/index.css";
// import 'sweetalert/dist/sweetalert.css';
import "./assets/css/sass/custom.sass";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { userGet } from './utils/api'
import { REQUEST_SUCCESS } from './constants/appConfig'
import { PrivateRoute } from './components/auth/PrivateRoute'
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
export const AuthContext = createContext();

const ViewMain = React.lazy(() => import(/* webpackChunkName: "views" */ './views'));
const ViewApp = React.lazy(() => import(/* webpackChunkName: "views-app" */ './views/app'));
const ViewUser = React.lazy(() => import(/* webpackChunkName: "views-user" */ './views/user'));
const ViewError = React.lazy(() => import(/* webpackChunkName: "views-error" */ './views/error'));

// const reducer = (state, action) => {
//   switch (action.type) {
//       case "LOGIN":
//         return {
//             ...state,
//             isAuthenticated: true
//         };
//       case "LOGOUT":
//         return {
//             ...state,
//             isAuthenticated: false,
//             user: null
//         };
//       case "SET_USERINFO":
//         return {
//             ...state,
//             isAuthenticated: true,
//             user: action.payload
//         };
//       default:
//           return state
//   }
// }

const App = () => {
  // const [state, dispatch] = useReducer(reducer, {
  //   isAuthenticated: false,
  //   user: null
  // });

  const [isToken, setIsToken] = useState(false);
  const [user, setUser] = useState(null)
  const setToken = (data) => {
    if (data) {
      setIsToken(true);
      localStorage.setItem("token", data);
    }
    else {
      setIsToken(false);
      localStorage.setItem("token", data);
    }
  }

  const validateToken = () => {
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      userGet(localStorage.getItem("user"))
        .then(res => {
          if (res.data.code === REQUEST_SUCCESS) {
            setIsToken(true);
            setUser(res.data.user);
          } else {
            localStorage.clear();
            setIsToken(false);
            setToken('');
            setUser(null);
            // TODO: add react notification
          }
        })
        .catch(err => {
          console.log(err.config)
          localStorage.clear();
          setIsToken(false);
          setToken('');
          setUser(null);
        })
    }
    else {
      setIsToken(false);
    }
  }

  return (
    <div className="h-100">
      <>
        <NotificationContainer />
        <Suspense fallback={<div className="loading" />}>
          <Router>
            <Switch>
              <Route
                path="/"
                exact
                render={props => <ViewMain {...props} />}
              />
              <AuthContext.Provider value={{ isToken, setIsToken, setToken, validateToken, user, setUser }}>

                <PrivateRoute
                  path="/admin"
                  component={ViewApp}
                />
                <Route
                  path="/user"
                  render={props => <ViewUser {...props} />}
                />
              </AuthContext.Provider>
              <Route
                path="/error"
                exact
                render={props => <ViewError {...props} />}
              />
              
              <Redirect to="/error" />
            </Switch>
          </Router>
        </Suspense>
      </>
    </div>
  );
}

export default App;
